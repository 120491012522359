<template>
  <div>
    <vx-card actionable class="cardx" :title="'Enrollments: ' + `${this.countsleads}`">
      <vs-row vs-w="12" style="margin-bottom: 2%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1.5">
          <label>Enrollments Date :</label>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <!-- <label>Enrollments Date</label> -->
          <date-range-picker
            ref="picker"
            :opens="'right'"
            :locale-data="{
              firstDay: 1,
              format: 'DD-MMM-YYYY',
              customRangeLabel: 'Custom Range',
            }"
            :minDate="null"
            :maxDate="null"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="true"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="false"
            v-model="dateRange"
            style="width: 100%"
            @update="analyzePagination()"
          ></date-range-picker>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <v-select
            multiple
            :options="cityOptions"
            v-model="selectedCity"
            placeholder="City"
            class="w-full"
            
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              City
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect 
                :value-consists-of="'LEAF_PRIORITY'"   
                :multiple="true" value-format="object"   
                :options="city_classification_list" 
                placeholder="Select Spoc" 
                v-model="selected_city" />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              SPOC
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedBDE"
                :multiple="true"
                :options="treeDataBDE"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
          <!-- <vs-dropdown vs-custom-content vs-trigger-click v-if="getrole('cm4')">
            <a class="a-icon" href.prevent @click="showTreeBox">
              SR
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedBDESR"
                :multiple="true"
                :options="treeDataBDESR"
              />
            </vs-dropdown-menu>
          </vs-dropdown> -->
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
          <vs-dropdown vs-custom-content vs-trigger-click v-if="getrole('cm4')">
            <a class="a-icon" href.prevent @click="showTreeBox">
              SR
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedBDESR"
                :multiple="true"
                :options="treeDataBDESR"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              Courses
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedCourses"
                :multiple="true"
                :options="treeDataCourses"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col  vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
          <vs-select
            placeholder="Select CFA Level"
            v-if="initSelectedCourses.includes('CFA')"
            width="100%"
            v-model="selected_cfa_level"
            multiple
          >
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item,index) in cfa_levels_list" />
          </vs-select>
          <vs-select
            style="margin-left: 2%;"
            placeholder="Select FRM Part"
            v-if="initSelectedCourses.includes('FRM')"
            width="100%"
            v-model="selected_frm_part"
            multiple
          >
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item,index) in frm_parts_list" />
          </vs-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1.5">
          <vs-dropdown class="gap" vs-custom-content vs-trigger-click :key="dropdownKey">
            <vs-button radius color="dark" type="gradient" icon="search"></vs-button>
            <vs-dropdown-menu class="loginx">
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-input
                    type="text"
                    label-placeholder="Name"
                    v-model="value1"
                    :style="{ width: '200px' }"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzePagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="9">
                  <vs-input
                    type="text"
                    label-placeholder="Can-Id"
                    v-model="can_id"
                    :style="{ width: '200px' }"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzePagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <!-- <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  
                 <v-select style="width: 100%; margin-top: 20px;"
                    placeholder="Course"
                    v-model="course"
                    :options="treeDataCourses"
                ></v-select>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzePagination()"
                  ></vs-button>
                </vs-col>
              </vs-row> -->
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-input type="text" label-placeholder="Batch" v-model="batch" />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzePagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-input type="text" label-placeholder="Designation" v-model="designation_search" />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzePagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <br/>
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <v-select
                    style="width: 100%"
                    :options="loan_status_list"
                    v-model="selected_loan_status"
                    placeholder="Loan Status"
                  />
                </vs-col>
                <vs-col
                  
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzePagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <br/>
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <v-select
                    style="width: 100%"
                    :options="tenure_list"
                    v-model="selected_tenure"
                    placeholder="Tenure"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzePagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <br/>
              <vs-row vs-w="12" style="margin-bottom: 3%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-checkbox v-model="cpa_cma_filter">CPA & CMA Enrolled</vs-checkbox>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzePagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <br/>
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-button
            radius
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refresh()"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 1%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <b>Search Criteria:</b>
        </vs-col>
        <vs-col
          style="overflow-y: scroll"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
        >
          <div v-for="(chip, index) in searchCriteria" :key="index">
            <vs-chip
              v-if="chip !== 'divider'"
              closable
              @click="removechip(chip)"
              color="primary"
            >
              <b>{{ chip }}</b>
            </vs-chip>
            <div v-else>
              <div class="vl"></div>
            </div>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
          <vs-button
            color="dark"
            type="gradient"
            icon="search"
            @click="analyzePagination()"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col vs-w="12">
          <datatable :selected_courses="initSelectedCourses"></datatable>
        </vs-col>
      </vs-row>

      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
          <vs-chip color="primary" style="margin-right: 2%">
            <b>{{ countsleads }}</b>
          </vs-chip>
          <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import datatable from "../components/pagesComponents/EnrollmentDatatable.vue";
import axios from "axios";
import constants from "../../constants.json";
import "vue-tree-halower/dist/halower-tree.min.css";
import Treeselect from "@riophae/vue-treeselect";
// import _ from "lodash";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import "vue-tree-halower/dist/halower-tree.min.css";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
// import EventBus from "../components/eventbus.js";
import { VTree, VSelectTree } from "vue-tree-halower";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    datatable,
    Treeselect,
    Datepicker,
    VTree,
    VSelectTree,
    DateRangePicker,
    "v-select": vSelect,
    VuePerfectScrollbar,
  },
  data() {
    return {
      cpa_cma_filter: false,
      dropdownKey: 0,
      selected_loan_status: "",
      loan_status_list: ["Yes", "No"],
      selected_tenure: null,
      tenure_list: [0, 3, 6],
      cities_list: [],
      selected_city: [],
      ccf_spoc_ids: [],
      city_classification_list: [
        {
          id: "All",
          label: "All",
          children: []
        }
      ],
      selected_frm_part: [],
      frm_parts_list: ["Part 1", "Part 2"],
      selected_cfa_level: [],
      cfa_levels_list: ["Level 1", "Level 2", "Level 3"],
      countsleads: 0,
      cityOptions: [
        "Ahmedabad",
        "Bangalore",
        "Chennai",
        "Delhi",
        "Ernakulam",
        "Hyderabad",
        "Kolkata",
        "Mumbai",
        "Pune",
      ],
      selectedCity: [],
      dateRange: {
        startDate: "",
        endDate: "",
      },
      emailStatus: ["Sent", "old Data"],
      agreementStatus: ["Complete", "Incomplete"],
      enrollment_date: {
        startDate: "",
        endDate: "",
      },
      canIdWidth: "100px",
      spocLogin: false,
      currentTablePage: 1,
      searchCriteria: [],
      tablelinks: 1,
      usercity: "",
      name: "",
      searchIn: {
        id: "",
        name: "",
      },
      can_id: "",
      rawBde: [],
      rawBdeSR: [],
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      designation_search: "",
      course: "",
      courses: [],
      batch: "",
      agreement_status: "",
      email_status: "",
      mwb_names: [],
      SpocCityPrefilled: [],
      SpocPrefilled: "",
      initSelectedBDE: [],
      initSelectedBDESR: [],
      comments: "",
      initSelectedCourses: [],
      initSelectedLevels: [],
      infosearch: [],
      enrollmentstotalCount: "0",
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      treeDataBDESR: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      treeDataCourses: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "CPA",
              label: "CPA",
            },
            {
              id: "CPA-AA",
              label: "CPA-AA"
            },
            {
              id: "CPA-US",
              label: "CPA-US"
            },
            // {
            //   id: "AICPA",
            //   label: "AICPA",
            // },
            // {
            //   id: "CISA",
            //   label: "CISA",
            // },
            // {
            //   id: "EA",
            //   label: "EA",
            // },
            // {
            //   id: "CIA",
            //   label: "CIA",
            // },
            {
              id: "CMA",
              label: "CMA",
            },
            {
              id: "CFA",
              label: "CFA",
            },
            {
              id: "FRM",
              label: "FRM",
            },
            {
              id: "USP",
              label: "USP",
            },
            // {
            //   id: "RPO",
            //   label: "RPO"
            // },
            // {
            //   id: "IIML-FA",
            //   label: "IIML-FA",
            // },
            // {
            //   id: "IIML-SF",
            //   label: "IIML-SF",
            // },
            {
              id: "IIML-FT",
              label: "IIML-FT",
            },
            // {
            //   id: "IIML-DA",
            //   label: "IIML-DA",
            // },
            // {
            //   id: "IIML-HR",
            //   label: "IIML-HR",
            // },
            // {
            //   id: "IITR-BF",
            //   label: "IITR-BF",
            // },
            // {
            //   id: "IITR-DB",
            //   label: "IITR-DB",
            // },
            // {
            //   id: "IITM-AA",
            //   label: "IITM-AA",
            // },
            // {
            //   id: "IIMK-CX",
            //   label: "IIMK-CX",
            // },
            // { id: "IITM-FS", label: "IITM-FS" },
            // { id: "IITR-CC", label: "IITR-CC" },
            // { id: "IIMK-FT", label: "IIMK-FT" },
            // { id: "IIML-AB", label: "IIML-AB" },
            // { id: "IIML-SH", label: "IIML-SH" },
            // { id: "IITJ-DE", label: "IITJ-DE" },
            { id: "XLRI-HR", label: "XLRI-HR" },
            { id: "XLRI-SH", label: "XLRI-SH" },
            { id: "IIMI-BA", label: "IIMI-BA" },
            { id: "IIMI-AA", label: "IIMI-AA" },
            // { id: "XLRI-DM", label: "XLRI-DM" },
            // {
            //   id: "IIML-PM",
            //   label: "IIML-PM",
            // },
            // {
            //   id: "MAYBE",
            //   label: "MAYBE"
            // }
          ],
        },
      ],
    };
  },
  mounted() {
    this.refresh();
    if (
      localStorage.getItem("role") === "cm4" ||
      localStorage.getItem("role") === "ac2"
    ) {
      this.spocLogin = false;
      this.getSpocAdmin();
    } else {
      this.spocLogin = true;
      this.getSpoc();
    }
    this.getSRSpocs();
    // this.getEnrollments(1);
  },
  watch: {
    selected_city(val){
      this.ccf_spoc_ids = [];
      val.forEach(element => {
        this.ccf_spoc_ids.push(element.id);
      });
      this.mergeAllSearch();
    },
    selected_cfa_level(val){
      console.log("selected_cfa_level", val);
      this.mergeAllSearch();
    },
    selected_frm_part(val){
      console.log("selected_frm_part", val);
      this.mergeAllSearch();
    },
    currentTablePage(value) {
      this.getEnrollments(value);
    },
    initSelectedBDESR: function () {
      this.mergeAllSearch();
    },
    initSelectedBDE: function () {
      this.mergeAllSearch();
    },
    initSelected: function () {
      this.mergeAllSearch();
    },
    initSelectedCourses: function () {
      if(!this.initSelectedCourses.includes('CFA')){
        if(this.searchCriteria.includes('Level 3')){
          this.selected_cfa_level.splice(this.selected_cfa_level.indexOf('Level 3'), 1);
        }
        if(this.searchCriteria.includes('Level 2')){
          this.selected_cfa_level.splice(this.selected_cfa_level.indexOf('Level 2'), 1);
        }
        if(this.searchCriteria.includes('Level 1')){
          this.selected_cfa_level.splice(this.selected_cfa_level.indexOf('Level 1'), 1);
        }
      }
       if(!this.initSelectedCourses.includes('FRM')){
        
        if(this.searchCriteria.includes('Part 2')){
          this.selected_frm_part.splice(this.selected_frm_part.indexOf('Part 2'), 1);
        }
        if(this.searchCriteria.includes('Part 1')){
          this.selected_frm_part.splice(this.selected_frm_part.indexOf('Part 1'), 1);
        }
      }
      this.mergeAllSearch();
    },
    initSelectedLevels: function () {
      this.mergeAllSearch();
    },
    initSelectedLevelsIIML: function () {
      this.mergeAllSearch();
    },

    infosearch: function () {
      this.mergeAllSearch();
    },
    selectedCity: function() {
      this.searchData = [];
      this.searchData.push(this.selectedCity);
      // console.log("this.SelectCity", this.searchCriteria.split(',')); 
      this.searchData.forEach(element => {
        
        // element.forEach(city => {
          console.log("city", this.searchData);
          this.SelectCity = element.toString();
          console.log("city", this.SelectCity);
        // });
        
      });
    }
  },
  methods: {
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    showTreeBox() {
      return this.treeDataBDE;
    },
    analyzePagination() {
      this.dropdownKey = Math.random()
      if (this.selectedpagination === 1) {
        this.getEnrollments(1);
      } else {
        this.selectedpagination = 1;
      }
    },
    getAgreementStatus(agreed_status) {
      switch (agreed_status) {
        case "Incomplete":
          return 0;
        case "Complete":
          return 1;
      }
    },
    getAllSpoc() {
      let url = null;
      if (this.getrole("exed2")) {
          url = `${constants.SERVER_API}getExed`;
      } else {
          url = `${constants.SERVER_API}getSpocs`;
      }
      axios
          .get(url, {
              headers: {
                  Authorization: `Bearer ${localStorage.userAccessToken}`,
              },
          })
          .then((response) => {
              console.log(response);
              this.logged_in_user_id = response.data.logged_in_user_id;
              // this.registeredSpoc = response.data.logged_in_user_id;
              // response.data.spocs.forEach((spoc) => {
              //     if (spoc.id === this.registeredSpoc) {
              //         // console.log(spoc, "found the spoc");
              //         this.identityCityCode = spoc.city.charAt(0);
              //         // console.log(this.identityCityCode);
              //     }
              // });
              // this.extractSpocByCity(response.data.spocs);
              // this.getReceipts();
          })
          .catch((error) => {
              this.handleError(error);
          });
    },
    getEnrollments(page) {
      this.$vs.loading();
      if(this.cpa_cma_filter == true){
        this.cpa_cma_filter = 1;
      }
      if(this.cpa_cma_filter == false){
        this.cpa_cma_filter = 0;
      }
      let params = {
        page: page,
        can_id: this.getCanId(),
        spoc_ids: this.retrieveSelectedSpoc(),
        //spoc_ids: bdeid.join(),
        course: this.retrieveSelectedCourse(),
        batch: this.batch,
        name: this.value1,
        designation: this.designation_search,
        cfa_selected_level: this.selected_cfa_level.join(),
        frm_selected_part: this.selected_frm_part.join(),
        agreement_status: this.getAgreementStatus(this.agreement_status),
        email_status: this.email_status,
        last_call_start_date: "",
        last_call_end_date: "",
        enrollment_start_date: "",
        enrollment_end_date: "",
        city: this.SelectCity,
        cpa_cma: this.cpa_cma_filter,
      };

      if (this.dateRange.startDate !== "" && this.dateRange.endDate !== "") {
        params.enrollment_start_date = this.datatoTimestamp(this.dateRange.startDate);
        params.enrollment_end_date = this.datatoTimestamp(this.dateRange.endDate);
      }

      if(this.selected_loan_status != ""){
        if(this.selected_loan_status == "Yes"){
         params.loan_status = 1;
        }
        if(this.selected_loan_status == "No"){
          params.loan_status = 0;
        }
      }
      if(this.selected_tenure != null){
        params.tenure = this.selected_tenure;
      }
      // if (
      //   this.enrollment_date.startDate !== "" &&
      //   this.enrollment_date.endDate !== ""
      // ) {
      //   params.enrollment_start_date = this.datatoTimestamp(
      //     this.enrollment_date.startDate
      //   );
      //   params.enrollment_end_date = this.datatoTimestamp(
      //     this.enrollment_date.endDate
      //   );
      // }

      console.log("params",params);
      // return;
      axios
        .get(`${constants.SERVER_API}getEnrollments`, {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "error") {
            this.handleNotification(response);
          } else {
            this.countsleads = response.data.enrollments.total;
            response.data.enrollments.data.forEach((lead) => {
              lead.engagements = [];
              lead.mobile = [];
              lead.emails = [];
            });
            this.currentTablePage = response.data.enrollments.current_page;
            this.tablelinks = response.data.enrollments.last_page;
            this.$store.commit("MOUNT_MWB_LEADS", response.data.enrollments.data);
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    getCanId() {
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "ac2"
      ) {
        return this.can_id;
      } else {
        if (this.can_id !== "") {
          let spoc_can_id = `${this.can_id}`;
          return spoc_can_id;
        } else {
          return this.can_id;
        }
      }
    },
    retrieveSelectedSpoc() {
      let bde = [];
      this.courses = [];
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "ac2"
      ) {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
        this.initSelectedBDESR.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDESR[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
              // console.log("bdesr",bde)
            });
          } else {
            let city = false;
            this.treeDataBDESR[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  bde.push(acutal_spoc.label);
                });
                // console.log("bdesr",bde)
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
        this.initSelectedCourses.forEach((sort) => {
          if (sort === "All") {
            this.treeDataCourses[0].children.forEach((child) => {
              this.courses.push(child.label);
            });
          } else {
            this.courses.push(sort);
          }
        });
      } else {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              bde.push(child.label);
            });
          } else {
            bde.push(sort);
          }
        });
        this.initSelectedBDESR.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDESR[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
              console.log("bdesr", bde);
            });
          } else {
            let city = false;
            this.treeDataBDESR[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
      }
      let bdeid = [];
      console.log("bdesr", bde);
      this.ccf_spoc_ids.forEach(element => {
        bdeid.push(element);  
      });
      bde.forEach((spoc) => {
        this.rawBde.forEach((raw) => {
          if (raw.full_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      bde.forEach((spoc) => {
        this.rawBdeSR.forEach((raw) => {
          if (raw.first_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      return bdeid.join();
    },
    retrieveSelectedCourse(){
      this.initSelectedCourses.forEach((sort) => {
          if (sort === "All") {
            this.treeDataCourses[0].children.forEach((child) => {
              this.courses.push(child.label);
            });
          } else {
            this.courses.push(sort);
          }
          console.log("this.courses",this.courses)
        });
        return this.courses.join();
    },
    refresh() {
      this.can_id = "";
      this.initSelectedBDE = [];
      this.name = "";
      this.value1 = "";
      this.designation_search = "";
      this.can_id = "";
      this.batch = "";
      this.course = "";
      this.dateRange.startDate = "";
      this.dateRange.endDate = "";
      this.selectedCity = "";
      this.cpa_cma_filter = false;
      this.analyzePagination();
    },
    getSpoc() {
      this.initSelectedBDE = [];
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.rawBde = [];
          // let usercity = "";
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.rawBdeSR.forEach((sr)=>{
            this.rawBde.push(sr);
          })
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          console.log(unique, "Unique");
          this.SpocCityPrefilled = [];
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.SpocCityPrefilled.push(key.charAt(0).toUpperCase());
            }
          }
          response.data.spocs.forEach((spoc) => {
            if (spoc.id === this.searchIn.id) {
              this.usercity = spoc.city;
              this.spoc_city = spoc.city;
              this.SpocPrefilled = spoc.city.charAt(0).toUpperCase();
            }
          });
          response.data.spocs.forEach((spoc) => {
            if (spoc.city === this.usercity) {
              this.rawBde.push(spoc);
            }
          });
          this.treeDataBDE[0].children = [];
          this.rawBde.forEach((data) => {
            var obj = {
              id: data.first_name,
              label: data.first_name,
            };
            this.treeDataBDE[0].children.push(obj);
          });
          
          if (localStorage.getItem("role") === "cm1") {
            this.initSelectedBDE.push(this.searchIn.name);
          }
          // this.search_API(1);
          this.getSRSpocs();
          this.getEnrollments(1);
        })
        .catch((error) => {
          
          this.handleError(error);
        });
    },
    // getSpoc() {
    //   let url = `${constants.ADMIN_SERVER_API}getSpocs`;
    //   axios
    //     .get(url, {
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
    //     })
    //     .then(async (response) => {
    //       this.searchIn.id = response.data.logged_in_user_id;
    //       this.searchIn.name = response.data.logged_in_user_name;
    //       this.rawBde = response.data.spocs;
    //       this.rawBdeSR.forEach((sr)=>{
    //         this.rawBde.push(sr);
    //       })
    //       this.treeDataBDE[0].children = [];
    //       response.data.spocs.forEach((data) => {
    //         var obj = {
    //           id: data.first_name,
    //           label: data.first_name,
    //         };
    //         this.treeDataBDE[0].children.push(obj);
    //       });
    //       this.initSelectedBDE.push(this.searchIn.name);
    //       this.getEnrollments(1);
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },
    getSpocAdmin() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log(response);
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.rawBde = response.data.spocs;
          this.treeDataBDE[0].children = [];
          this.rawcities = [];
          this.rawBdeSR.forEach((sr)=>{
            this.rawBde.push(sr);
          })
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.treeDataBDE[0].children.push(obj);
            }
          }
          response.data.spocs.forEach((server_spoc) => {
            this.treeDataBDE.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.full_name,
                    label: server_spoc.full_name,
                  };
                  bdechid.children.push(obj);
                }
              });
            });
          });
          if (this.rawBde.indexOf(this.searchIn) !== -1) {
            this.initSelectedBDE.push(this.searchIn.name);
          }
          // this.search_API(1);
          this.getCityClassificationSpocs(response.data.spocs);
          this.getSRSpocs();
          this.getEnrollments(1);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getCityClassificationSpocs(spocs_data){
      this.cities_list  = [...new Set(spocs_data.map((item) => item.city)), ];
      console.log("cities list order",this.cities_list);
      this.city_classification_list[0].children = [];
      for (let i = 0; i < this.cities_list.length; i++) {
          const element = this.cities_list[i];
          if(element != null){
            var obj = {
              id: element,
              label: element,
              children: [],
            };
            this.city_classification_list[0].children.push(obj);
          }
      }

      this.city_classification_list[0].children = this.sortNestedArrayAlphabetically(
        this.city_classification_list[0].children
      );
      spocs_data.forEach(element => {
        this.city_classification_list[0].children.forEach(city => {
          if(element.city == city.label){
            if (!city.children.find(item => item.label === element.city_classification)) { //to remove city classification duplicates
              if(element.city_classification != null){
                var obj = {
                  id: element.city + "_" + element.city_classification, // x is in more than one city
                  label: element.city_classification,
                  children: [],
                };
                city.children.push(obj);
              }
            }
          }
        });   
      });
      spocs_data.forEach(element => {
        this.city_classification_list[0].children.forEach(city => {
          city.children.forEach(ccf => {
            if(element.city == city.label && element.city_classification == ccf.label){
              var obj = {
                id: element.id,
                label: element.full_name,
              };
              ccf.children.push(obj);
            }  
          });  
        });
      });
    },
    // getSpocAdmin() {
    //   let url = `${constants.ADMIN_SERVER_API}getSpocs`;
    //   axios
    //     .get(url, {
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
    //     })
    //     .then((response) => {
    //       this.searchIn.id = response.data.logged_in_user_id;
    //       this.searchIn.name = response.data.logged_in_user_name;
    //       response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
    //       this.rawBde = response.data.spocs;
    //       this.rawBdeSR.forEach((sr)=>{
    //         this.rawBde.push(sr);
    //       })
    //       // this.treeDataBDE[0].children = [];
    //       this.rawcities = [];
    //       var unique = response.data.spocs
    //         .map((name) => {
    //           return {
    //             count: 1,
    //             name: name.city,
    //           };
    //         })
    //         .reduce((a, b) => {
    //           a[b.name] = (a[b.name] || 0) + b.count;
    //           return a;
    //         }, {});
    //       for (var key in unique) {
    //         if (unique.hasOwnProperty(key)) {
    //           this.rawcities.push(key);
    //           var obj = {
    //             id: key,
    //             label: key,
    //             children: [],
    //           };
    //           this.treeDataBDE[0].children.push(obj);
    //         }
    //       }
    //       response.data.spocs.forEach((server_spoc) => {
    //         this.treeDataBDE.forEach((bde) => {
    //           bde.children.forEach((bdechid) => {
    //             if (bdechid.label === server_spoc.city) {
    //               var obj = {
    //                 id: server_spoc.first_name,
    //                 label: server_spoc.first_name,
    //               };
    //               bdechid.children.push(obj);
    //             }
    //           });
    //         });
    //       });
    //       this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
    //         this.treeDataBDE[0].children
    //       );
    //       if (this.rawBde.indexOf(this.searchIn) !== -1) {
    //         this.initSelectedBDE.push(this.searchIn.name);
    //       }
    //       // else {
    //       // this.initSelectedBDE.push(this.treeDataBDE[0].children[0].label);
    //       // }
    //       this.getEnrollments(1);
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },
    getSRSpocs() {
      let url = `${constants.SERVER_API}getSRSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.rawBdeSR = response.data.spocs;
          let sr = response.data.spocs;
          let sr_spocs = [];
          for (let i = 0; i < sr.length; i++) {
            const element = sr[i];
            if(element.team == "SR" && element.sub_team == "SR"){
             sr_spocs.push(element);
            }
          }
          this.treeDataBDESR[0].children = [];
          this.rawcities = [];
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.treeDataBDESR[0].children.push(obj);
            }
          }
          sr_spocs.forEach((server_spoc) => {
            this.treeDataBDESR.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.first_name,
                    label: server_spoc.full_name,
                  };
                  bdechid.children.push(obj);
                }
              });
            });
          });
          if (this.rawBdeSR.indexOf(this.searchIn) !== -1) {
            this.initSelectedBDE.push(this.searchIn.name);
          }
          // this.search_API(1);
          this.getEnrollments(1);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    mergeAllSearch() {
      this.searchCriteria = [];
      this.searchCriteria.push("divider");
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr"
      ) {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                console.log("if spoc child", acutal_spoc);
                this.searchCriteria.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  console.log("else spoc child", acutal_spoc);
                  this.searchCriteria.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              this.searchCriteria.push(sort);
            }
          }
        });

        this.searchCriteria.push("divider");
        this.selected_city.forEach(element => {
          this.searchCriteria.push(element.label);  
        });

      } else {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              this.searchCriteria.push(child.label);
            });
          } else {
            this.searchCriteria.push(sort);
          }
        });
      }
      this.searchCriteria.push("divider");

      if (localStorage.getItem("role") === "cm4") {
        this.initSelectedBDESR.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDESR[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                this.searchCriteria.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDESR[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  this.searchCriteria.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              this.searchCriteria.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDESR.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDESR[0].children.forEach((child) => {
              this.searchCriteria.push(child.label);
            });
          } else {
            this.searchCriteria.push(sort);
          }
        });
      }

      this.searchCriteria.push("divider");
      this.initSelectedCourses.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      this.selected_cfa_level.forEach((sort) => {
        this.searchCriteria.push(sort);
        // }
      });
      this.searchCriteria.push("divider");
      this.selected_frm_part.forEach((sort) => {
        this.searchCriteria.push(sort);
        // }
      });
      this.searchCriteria.push("divider");
      this.initSelectedLevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      if (this.initSelected !== "" && this.initSelected !== undefined) {
        this.searchCriteria.push(this.initSelected);
      }
      this.searchCriteria.push("divider");
      this.infosearch.forEach((info) => {
        this.searchCriteria.push(info);
      });
      this.searchCriteria.push("divider");
      // if (
      //   localStorage.getItem("role") === "cm4" ||
      //   localStorage.getItem("role") === "wc" ||
      //   localStorage.getItem("role") === "ac2" ||
      //   localStorage.getItem("role") === "fe" ||
      //   localStorage.getItem("role") === "cmca" ||
      //   localStorage.getItem("role") === "baca" ||
      //   localStorage.getItem("role") === "hr"
      // ) {
      //           this.searchCriteria.push(this.selectedCity);   
      //     }
    },
    removechip(chip) {
      // console.log(this.treeDataBDE);
      // console.log(this.treeDataLevels);
      // console.log(this.treeDataCourses);
      // console.log(this.treeDatafocus);
      if (this.infosearch.indexOf(chip) !== -1) {
        this.infosearch.splice(this.infosearch.indexOf(chip), 1);
      }
      let chipPlacement = "";
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr"
      ) {
        this.treeDataBDE[0].children.forEach((child) => {
          child.children.forEach((child2) => {
            if (child2.label === chip) {
              chipPlacement = "bde";
            }
          });
        });
      } else {
        this.treeDataBDE[0].children.forEach((child) => {
          if (child.label === chip) {
            chipPlacement = "bde";
          }
        });
      }
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr"
      ) {
        this.treeDataBDESR[0].children.forEach((child) => {
          child.children.forEach((child2) => {
            if (child2.label === chip) {
              chipPlacement = "sr";
            }
          });
        });
      } else {
        this.treeDataBDESR[0].children.forEach((child) => {
          if (child.label === chip) {
            chipPlacement = "sr";
          }
        });
      }

      this.selected_city.forEach(element => {
        if (element.label === chip) {
          chipPlacement = "ccf";
        }
      });

      this.treeDataCourses[0].children.forEach((child) => {
        if (child.label === chip) {
          chipPlacement = "courses";
        }
      });
      if(chip=='CFA'){
        if(this.searchCriteria.includes('Level 1')){
          this.selected_cfa_level.splice(this.selected_cfa_level.indexOf('Level 1'), 1);
        }
        if(this.searchCriteria.includes('Level 2')){
          this.selected_cfa_level.splice(this.selected_cfa_level.indexOf('Level 2'), 1);
        }
        if(this.searchCriteria.includes('Level 3')){
          this.selected_cfa_level.splice(this.selected_cfa_level.indexOf('Level 3'), 1);
        }
      }
      if(chip=='FRM'){
        if(this.searchCriteria.includes('Part 1')){
          this.selected_frm_part.splice(this.selected_frm_part.indexOf('Part 1'), 1);
        }
        if(this.searchCriteria.includes('Part 2')){
          this.selected_frm_part.splice(this.selected_frm_part.indexOf('Part 2'), 1);
        }
      }
      this.cfa_levels_list.forEach((child) => {
        if(child == chip){
          chipPlacement = "cfa_levels";
        }
      })
      this.frm_parts_list.forEach((child) => {
        if(child == chip){
          chipPlacement = "frm_parts";
        }
      })
      if (this.initSelected === chip) {
        this.initSelected = undefined;
      }
      switch (chipPlacement) {
        case "bde":
          if (this.initSelectedBDE.indexOf(chip) === -1) {
            if (
              localStorage.getItem("role") === "cm4" ||
              localStorage.getItem("role") === "wc" ||
              localStorage.getItem("role") === "ac2" ||
              localStorage.getItem("role") === "fe" ||
              localStorage.getItem("role") === "cmca" ||
              localStorage.getItem("role") === "baca" ||
              localStorage.getItem("role") === "hr"
            ) {
              if (this.initSelectedBDE.indexOf("All") !== -1) {
                this.initSelectedBDE = [];
                this.treeDataBDE[0].children.forEach((child) => {
                  child.children.forEach((child2) => {
                    if (child2.label !== chip) {
                      this.initSelectedBDE.push(child2.label);
                    }
                  });
                });
              } else {
                let city = "";
                this.treeDataBDE[0].children.forEach((child) => {
                  child.children.forEach((child2) => {
                    if (child2.label === chip) {
                      city = child.label;
                    }
                  });
                });
                this.initSelectedBDE.splice(this.initSelectedBDE.indexOf(city), 1);
                this.treeDataBDE[0].children.forEach((child) => {
                  if (child.label === city) {
                    child.children.forEach((child2) => {
                      if (child2.label !== chip) {
                        this.initSelectedBDE.push(child2.label);
                      }
                    });
                  }
                });
              }
            } else {
              this.initSelectedBDE = [];
              this.treeDataBDE[0].children.forEach((child) => {
                if (child.label !== chip) {
                  this.initSelectedBDE.push(child.label);
                }
              });
            }
          } else {
            this.initSelectedBDE.splice(this.initSelectedBDE.indexOf(chip), 1);
          }
          break;
        case "ccf":
          this.selected_city.splice(
            this.selected_city.indexOf(chip),
            1
          );
          break;
        case "sr":
          if (this.initSelectedBDESR.indexOf(chip) === -1) {
            if (localStorage.getItem("role") === "cm4") {
              if (this.initSelectedBDESR.indexOf("All") !== -1) {
                this.initSelectedBDESR = [];
                this.treeDataBDESR[0].children.forEach((child) => {
                  child.children.forEach((child2) => {
                    if (child2.label !== chip) {
                      this.initSelectedBDESR.push(child2.label);
                    }
                  });
                });
              } else {
                let city = "";
                this.treeDataBDESR[0].children.forEach((child) => {
                  child.children.forEach((child2) => {
                    if (child2.label === chip) {
                      city = child.label;
                    }
                  });
                });
                this.initSelectedBDESR.splice(this.initSelectedBDESR.indexOf(city), 1);
                this.treeDataBDESR[0].children.forEach((child) => {
                  if (child.label === city) {
                    child.children.forEach((child2) => {
                      if (child2.label !== chip) {
                        this.initSelectedBDESR.push(child2.label);
                      }
                    });
                  }
                });
              }
            } else {
              this.initSelectedBDESR = [];
              this.treeDataBDESR[0].children.forEach((child) => {
                if (child.label !== chip) {
                  this.initSelectedBDESR.push(child.label);
                }
              });
            }
          } else {
            this.initSelectedBDESR.splice(this.initSelectedBDESR.indexOf(chip), 1);
          }
          break;
        case "courses":
          if (this.initSelectedCourses.indexOf(chip) === -1) {
            this.initSelectedCourses = [];
            this.treeDataCourses[0].children.forEach((child) => {
              if (child.label !== chip) {
                this.initSelectedCourses.push(child.label);
              }
            });
          } else {
            this.initSelectedCourses.splice(this.initSelectedCourses.indexOf(chip), 1);
          }
          break;
        case "cfa_levels":
            console.log("cfa_levels");
            if (this.selected_cfa_level.indexOf(chip) === -1) {
              this.selected_cfa_level = [];
              this.cfa_levels_list.forEach((child) => {
                if (child.label !== chip) {
                  this.selected_cfa_level.push(child.label);
                }
              });
            } else {
              this.selected_cfa_level.splice(this.selected_cfa_level.indexOf(chip), 1);
            }
          break;
          case "frm_parts":
          if (this.selected_frm_part.indexOf(chip) === -1) {
            this.selected_frm_part = [];
            this.frm_parts_list.forEach((child) => {
              if (child.label !== chip) {
                this.selected_frm_part.push(child.label);
              }
            });
          } else {
            this.selected_frm_part.splice(this.selected_frm_part.indexOf(chip), 1);
          }
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/pages/dropdown.scss";
.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}
.gap {
  margin-bottom: 4%;
}
.inicolor {
  color: gray;
}
.cardtext {
  color: gray;
  font-size: 20px;
}
.tree3 {
  // float: left;
  // width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px;
}
.vs-dropdown--menu {
  width: 300px;
  // height: 500px;
  // overflow: hidden;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gap {
  margin-right: 1%;
}
.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
i {
  font-size: 18px;
}
.vl {
  border-left: 1px solid black;
  height: 35px;
}
</style>
